import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import Loader from 'components/Loader';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectIsLoading,
  selectError,
  selectEvents,
} from 'redux/events/selectors';
import { signupToEvent } from 'redux/events/thunk';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from 'theme';

import {
  FormWrapper,
  InputLabel,
  TextInput,
  InputWrapper,
  Row,
  HelperText,
  ButtonWrapper,
  LinearLoaderWrapper,
} from './styled';

import Button from 'components/Button';
import Alert from 'components/Alert';
import { Participant } from 'api/models/Participant';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useMemo } from 'react';

const bellyTrackingValues = [
  'I can fly my spot',
  'I can fly my quadrant',
  "Moving around (can't hold my quadrant)",
];

const backTrackingValues = [
  'I can fly my slot',
  'I can fly my quadrant',
  "Moving around (can't hold my quadrant)",
  "I don't fly on my back",
];

const headUpValues = [
  'Flying my slot (docking)',
  'Flying on level ( No docking)',
  'Not stable',
  "I don't fly HU",
];

const headDownValues = [
  'Flying my slot (docking)',
  'Flying on level (No docking)',
  'Not stable',
  "I don't fly HD",
];

interface FormValues {
  name: string;
  email: string;
  numberOfJumps: number | null;
  surname?: string;
  phoneNumber?: string;
  tunnelHours?: number | null;
  trackingOnBelly?: string;
  trackingOnBack?: string;
  headUpSkills?: string;
  headDownSkills?: string;
  additionalAnswers?: string[];
}

interface SingupFormProps {
  eventId: string;
}

export default function SignupForm({ eventId }: SingupFormProps) {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const error = useAppSelector(selectError);
  const events = useAppSelector(selectEvents);

  const selectedEvent = useMemo(
    () => events.find((event) => event.id === eventId),
    [eventId],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('This field is required'),
    numberOfJumps: Yup.number().nullable().required('This field is required'),
  });

  const initialValues: FormValues = {
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    numberOfJumps: null,
    tunnelHours: null,
    trackingOnBelly: '',
    trackingOnBack: '',
    headUpSkills: '',
    headDownSkills: '',
    additionalAnswers:
      selectedEvent &&
      [...Array(selectedEvent.additionalQuestions?.length)].map(() => ''),
  };

  const handleSubmit = (values: FormValues) => {
    const participant: Participant = {
      ...values,
      surname: values.surname || '',
      numberOfJumps: values.numberOfJumps ? Number(values.numberOfJumps) : 0,
      tunnelHours: values.tunnelHours ? Number(values.tunnelHours) : 0,
    };

    dispatch(signupToEvent({ eventId, participant }));
  };

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <Row $twoElements>
              <InputWrapper $error={Boolean(errors.name)}>
                <InputLabel htmlFor="name">First name *</InputLabel>
                <TextInput
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={(event) =>
                    setFieldValue('name', event.target.value)
                  }
                  disabled={isLoading}
                />
                <HelperText>{errors.name}</HelperText>
              </InputWrapper>

              <div>
                <InputLabel htmlFor="surname">Last name</InputLabel>
                <TextInput
                  type="text"
                  name="surname"
                  id="surname"
                  value={values.surname}
                  onChange={(event) =>
                    setFieldValue('surname', event.target.value)
                  }
                  disabled={isLoading}
                />
              </div>
            </Row>

            <Row $twoElements>
              <InputWrapper $error={Boolean(errors.email)}>
                <InputLabel htmlFor="email">Email *</InputLabel>
                <TextInput
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={(event) =>
                    setFieldValue('email', event.target.value)
                  }
                  disabled={isLoading}
                />
                <HelperText>{errors.email}</HelperText>
              </InputWrapper>

              <div>
                <InputLabel htmlFor="phoneNumber">Phone number</InputLabel>
                <TextInput
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(event) =>
                    setFieldValue('phoneNumber', event.target.value)
                  }
                  disabled={isLoading}
                />
              </div>
            </Row>

            <Row>
              <InputLabel htmlFor="numberOfJumps">
                Total number of jumps *
              </InputLabel>

              <InputWrapper $error={Boolean(errors.numberOfJumps)}>
                <TextInput
                  type="number"
                  name="numberOfJumps"
                  id="numberOfJumps"
                  value={values.numberOfJumps || ''}
                  onChange={(event) =>
                    setFieldValue('numberOfJumps', event.target.value)
                  }
                  disabled={isLoading}
                />
                <HelperText>{errors.numberOfJumps}</HelperText>
              </InputWrapper>
            </Row>

            <Row>
              <InputLabel htmlFor="tunnelHours">Total tunnel hours</InputLabel>
              <TextInput
                type="number"
                name="tunnelHours"
                id="tunnelHours"
                value={values.tunnelHours || ''}
                onChange={(event) =>
                  setFieldValue('tunnelHours', event.target.value)
                }
                disabled={isLoading}
              />
            </Row>

            <FieldArray
              name="additionalAnswers"
              render={(arrayHelpers) => (
                <div>
                  {selectedEvent?.additionalQuestions &&
                    selectedEvent.additionalQuestions.length > 0 &&
                    values.additionalAnswers &&
                    values.additionalAnswers.length > 0 &&
                    values.additionalAnswers.map((answer, idx) => (
                      <Row key={idx}>
                        <InputLabel htmlFor={`additionalAnswers.${idx}`}>
                          {selectedEvent.additionalQuestions![idx]}
                        </InputLabel>

                        <Field name="additionalAnswers">
                          {() => (
                            <TextInput
                              type="text"
                              name="additionalAnswers"
                              id="tunnelHours"
                              value={values.tunnelHours || ''}
                              onChange={(event) =>
                                setFieldValue('tunnelHours', event.target.value)
                              }
                              disabled={isLoading}
                            />
                          )}
                        </Field>
                      </Row>
                    ))}
                </div>
              )}
            />

            <ThemeProvider theme={muiTheme}>
              <Row>
                <FormControl>
                  <FormLabel sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
                    Tracking on your belly
                  </FormLabel>
                  <RadioGroup
                    name="trackingOnBelly"
                    value={values.trackingOnBelly}
                    onChange={(e) =>
                      setFieldValue('trackingOnBelly', e.target.value)
                    }
                  >
                    {bellyTrackingValues.map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Row>

              <Row>
                <FormControl>
                  <FormLabel sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
                    Tracking on your back
                  </FormLabel>
                  <RadioGroup
                    name="trackingOnBack"
                    value={values.trackingOnBack}
                    onChange={(e) =>
                      setFieldValue('trackingOnBack', e.target.value)
                    }
                  >
                    {backTrackingValues.map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Row>

              <Row>
                <FormControl>
                  <FormLabel sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
                    What are your flying skills in Head Up?
                  </FormLabel>
                  <RadioGroup
                    name="headUpSkills"
                    value={values.headUpSkills}
                    onChange={(e) =>
                      setFieldValue('headUpSkills', e.target.value)
                    }
                  >
                    {headUpValues.map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Row>

              <Row>
                <FormControl>
                  <FormLabel sx={{ fontWeight: 'bold', color: '#1a1a1a' }}>
                    What are your flying skills in Head Down?
                  </FormLabel>
                  <RadioGroup
                    name="headDownSkills"
                    value={values.headDownSkills}
                    onChange={(e) =>
                      setFieldValue('headDownSkills', e.target.value)
                    }
                  >
                    {headDownValues.map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={value}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Row>
            </ThemeProvider>

            {isLoading && (
              <LinearLoaderWrapper>
                <Loader linear />
              </LinearLoaderWrapper>
            )}

            {error && <Alert type="error">{error}</Alert>}

            <ButtonWrapper>
              <Button dark type="submit" disabled={isLoading}>
                Sign up
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
}
