import styled, { createGlobalStyle, css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const GlobalStyles = createGlobalStyle`    
    html {
        font-size: 62.5%;        
    }

    body {
      margin: 0;
      font-family: 'Space Grotesk', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;     
      font-size: 1.6rem;     
      background-color: ${(props) => props.theme.colors.light};
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      outline: none;
      color: inherit;
    }
`;

// GLOBAL COMPONENTS
export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1655px;
  padding: 0 10rem;

  @media (max-width: 900px) {
    padding: 0 2rem;
  }
`;

interface NavLinkProps {
  $dark?: boolean;
}

export const MenuLink = styled.a<NavLinkProps>`
  color: ${(props) =>
    props.$dark ? props.theme.colors.primary : props.theme.colors.white};
  cursor: pointer;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid transparent;
  display: inline-block;
  line-height: 1.7rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-bottom: 0.1rem solid
      ${(props) =>
        props.$dark ? props.theme.colors.primary : props.theme.colors.white};
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid transparent;
  display: inline-block;
  line-height: 1.7rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.white};
  }
`;

interface TextButtonProps {
  $mt?: string;
}

export const TextButton = styled.button<TextButtonProps>`
  font-family: 'Space Grotesk', sans-serif;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.6rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: ${(props) => (props.$mt ? props.$mt : 0)};

  &:hover {
    text-decoration: underline;
  }
`;

interface ButtonProps {
  $dark?: boolean;
  $long?: boolean;
  $disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  font-family: 'Space Grotesk', sans-serif;
  height: 5.5rem;
  border: 0.1rem solid;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1.4rem ${(props) => (props.$long ? '1.7rem' : '1.2rem')};
  border-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  font-size: 1.6rem;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & .buttonArrow {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};

    & .buttonArrow {
      fill: ${(props) => props.theme.colors.primary};
    }
  }

  ${(props) =>
    props.$dark &&
    css`
      border-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};

      & .buttonArrow {
        fill: ${props.theme.colors.primary};
      }

      &:hover {
        background-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.white};

        & .buttonArrow {
          fill: ${props.theme.colors.white};
        }
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      border-color: ${props.theme.colors.gray.light};
      color: ${props.theme.colors.gray.light};

      & .buttonArrow {
        fill: ${props.theme.colors.gray.light};
      }

      &:hover {
        background-color: transparent;
        color: ${props.theme.colors.gray.light};

        & .buttonArrow {
          fill: ${props.theme.colors.gray.light};
        }
      }
    `};

  @media (max-width: 900px) {
    font-size: 1.4rem;
    padding: 0.8rem ${(props) => (props.$long ? '1.5rem' : '1rem')};
  }
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 1.8rem;
  }
`;

export const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
`;

export const Card = styled.article`
  background-color: ${(props) => props.theme.colors.white};
  padding: 4rem;
`;

interface LoadMoreButtonProps {
  $mt?: string;
}

export const LoadMoreButton = styled.button<LoadMoreButtonProps>`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid transparent;
  display: inline-block;
  line-height: 1.7rem;
  transition: all 0.2s ease-in-out;
  margin-top: ${(props) => props.$mt || '2.5rem'};
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;

  &:hover {
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.primary};
  }
`;
