import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: '1rem',
}));

export const EventImageContainer = styled('div')(() => ({
  width: '15rem',
  height: '15rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  borderRadius: '0.5rem',
  backgroundColor: '#ccc',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'opacity .2s ease-in-out',

  '&:hover': {
    opacity: 0.8,
  },
}));

export const EventImage = styled('img')(() => ({
  maxWidth: '100%',
  borderRadius: '0.5rem',
  width: '15rem',
  height: '15rem',
  objectFit: 'cover',
}));

export const DefaultIcon = styled(AddPhotoAlternateOutlinedIcon)(() => ({
  color: '#fff',
  width: '3.4rem',
  height: '3.4rem',
}));

export const AdditionalQuestionWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
}));

export const DeleteButton = styled('button')(() => ({
  outline: 'none',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  cursor: 'pointer',
  transition: 'all .2s ease-in-out',

  '&:hover': {
    opacity: '0.7',
  },
}));

export const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.light,
  verticalAlign: 'middle',
}));
