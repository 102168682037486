import { useCallback, useMemo } from 'react';
import { Descendant, createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import { Element } from './Element';
import { Leaf } from './Leaf';
import isHotkey from 'is-hotkey';
import { EditorWrapper, StyledEditor } from './styled';
import Toolbar from 'components/Dashboard/RichTextEditor/Toolbar';
import { CustomEditor } from 'components/Dashboard/RichTextEditor/CustomEditor';

interface HotKeys {
  [key: string]: string;
}

const HOTKEYS: HotKeys = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

interface Props {
  description: string;
  setDescription?: (value: string) => void;
  readOnly?: boolean;
}

export default function RichTextEditor({
  description,
  setDescription,
  readOnly = false,
}: Props) {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const initialValue: Descendant[] = useMemo(
    () =>
      description
        ? JSON.parse(description)
        : [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ],
    [description],
  );

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(value) => {
        if (readOnly || !setDescription) return;

        const isAstChange = editor.operations.some(
          (op) => op.type !== 'set_selection',
        );
        if (isAstChange) {
          const content = JSON.stringify(value);
          setDescription(content);
        }
      }}
    >
      <EditorWrapper>
        {!readOnly && <Toolbar />}

        <StyledEditor
          $readOnly={readOnly}
          readOnly={readOnly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={(event) => {
            Object.entries(HOTKEYS).forEach(([key, value]) => {
              if (isHotkey(key, event as any)) {
                event.preventDefault();
                CustomEditor.toggleMark(value, editor);
              }
            });
          }}
        />
      </EditorWrapper>
    </Slate>
  );
}
