import { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Event } from 'api/models/Event';
import { Formik, Form, FieldArray, Field } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addEvent, updateEvent } from 'redux/events/thunk';

import {
  StyledTextField,
  EventImageContainer,
  EventImage,
  DefaultIcon,
  AdditionalQuestionWrapper,
  StyledDeleteIcon,
  DeleteButton,
} from './styled';
import { selectIsLoading } from 'redux/events/selectors';
import RichTextEditor from 'components/Dashboard/RichTextEditor';
import { Typography } from '@mui/material';
import { TextButton } from 'theme/GlobalStyles';

interface AddEventDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedEvent?: Event;
  withDescription?: boolean; // helper field to render RTE properly
}

interface FormValues {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  location: string;
  instructors: string[];
  additionalQuestions?: string[];
}

const instructors = [
  'Jakub Langowski',
  'Piotr Noworol',
  'Tomasz Białous',
  'Łukasz Kaproń',
  'Tomasz Bała',
  'Radosław Kuczyński',
  'Guest Coach',
];

export default function AddEventDialog({
  isOpen,
  handleClose,
  selectedEvent,
  withDescription,
}: AddEventDialogProps) {
  const dispatch = useAppDispatch();

  const [eventImage, setEventImage] = useState<File | null>(null);
  const eventImageInput = useRef<HTMLInputElement>(null);
  const isLoading = useAppSelector(selectIsLoading);
  const [description, setDescription] = useState('');

  // set initial description
  useEffect(() => {
    if (!selectedEvent) return;

    setDescription(selectedEvent.description || '');
  }, [selectedEvent]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('To pole jest wymagane'),
    location: Yup.string().required('To pole jest wymagane'),
    startDate: Yup.date().required('To pole jest wymagane'),
    endDate: Yup.date().required('To pole jest wymagane'),
    instructors: Yup.array()
      .min(1, 'Dodaj co najmniej jednego instruktora')
      .required('To pole jest wymagane'),
  });

  const initialValues: FormValues = selectedEvent
    ? {
        name: selectedEvent.name,
        startDate: selectedEvent.date.start,
        endDate: selectedEvent.date.end,
        location: selectedEvent.location,
        instructors: selectedEvent.instructors,
        additionalQuestions: selectedEvent.additionalQuestions,
      }
    : {
        name: '',
        startDate: null,
        endDate: null,
        location: '',
        instructors: [],
        additionalQuestions: [],
      };

  const handleSubmit = (values: FormValues) => {
    const hasImage = selectedEvent?.imageUrl || eventImage;

    if (!values.startDate || !values.endDate || !hasImage)
      // eslint-disable-next-line
      return alert('Wypełnij wszyskie pola');

    const startDate = new Date(values.startDate.setHours(15));
    const endDate = new Date(values.endDate.setHours(15));

    console.log(startDate, endDate);

    const newEvent: Event = {
      name: values.name,
      date: {
        start: startDate,
        end: endDate,
      },
      location: values.location,
      instructors: values.instructors,
      participants: [],
      participantsNumber: selectedEvent?.participantsNumber || 0,
      signupsBlocked: false,
      imagePath: selectedEvent?.imagePath || undefined,
      imageUrl: selectedEvent?.imageUrl || undefined,
      description,
      additionalQuestions: values.additionalQuestions,
    };

    if (selectedEvent && selectedEvent.id) {
      newEvent.participants = selectedEvent.participants;

      dispatch(
        updateEvent({ eventId: selectedEvent.id, newEvent, eventImage }),
      );
    } else {
      dispatch(addEvent({ event: newEvent, eventImage: eventImage! }));
    }

    setDescription('');
    handleClose();
  };

  const handleImageClick = () => {
    if (!eventImageInput.current) return;

    eventImageInput.current.click();
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target.files) return;

    const file = event.target.files[0];
    setEventImage(file);
  };

  const renderRichTextEditor = () => {
    if (withDescription) {
      return (
        <div>
          {description && (
            <>
              <Typography sx={{ marginTop: '2rem' }}>Opis</Typography>
              <RichTextEditor
                description={description}
                setDescription={setDescription}
              />
            </>
          )}
        </div>
      );
    }

    if (!withDescription) {
      return (
        <>
          <Typography sx={{ marginTop: '2rem' }}>Opis</Typography>
          <RichTextEditor
            description={description}
            setDescription={setDescription}
          />
        </>
      );
    }

    return null;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => {
        setDescription('');
        handleClose();
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        {({ submitForm, values, setFieldValue, errors }) => (
          <>
            <DialogTitle>Dodaj nowy event</DialogTitle>
            <DialogContent>
              <Form>
                <EventImageContainer onClick={handleImageClick}>
                  {eventImage ? (
                    <EventImage
                      src={URL.createObjectURL(eventImage)}
                      alt="Event image"
                    />
                  ) : (
                    <span>
                      {selectedEvent?.imageUrl ? (
                        <EventImage
                          src={selectedEvent.imageUrl}
                          alt="Event image"
                        />
                      ) : (
                        <DefaultIcon />
                      )}
                    </span>
                  )}
                </EventImageContainer>

                {/* hidden image input */}
                <input
                  type="file"
                  ref={eventImageInput}
                  onChange={handleImageChange}
                  accept="image/png, image/jpeg"
                  hidden
                />

                <StyledTextField
                  name="name"
                  type="text"
                  label="Nazwa"
                  value={values.name}
                  onChange={(event) =>
                    setFieldValue('name', event.target.value)
                  }
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  disabled={isLoading}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack gap={1} direction="row" sx={{ marginBottom: '1rem' }}>
                    <DesktopDatePicker
                      label="Od"
                      inputFormat="dd/MM/yyyy"
                      value={values.startDate}
                      onChange={(newDate) =>
                        setFieldValue('startDate', newDate)
                      }
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          fullWidth
                          error={Boolean(errors.startDate)}
                          helperText={errors.startDate}
                        />
                      )}
                    />
                    <DesktopDatePicker
                      label="Do"
                      inputFormat="dd/MM/yyyy"
                      value={values.endDate}
                      onChange={(newDate) => setFieldValue('endDate', newDate)}
                      disabled={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          fullWidth
                          error={Boolean(errors.endDate)}
                          helperText={errors.endDate}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <StyledTextField
                  name="location"
                  type="text"
                  label="Lokalizacja"
                  value={values.location}
                  onChange={(event) =>
                    setFieldValue('location', event.target.value)
                  }
                  fullWidth
                  error={Boolean(errors.location)}
                  helperText={errors.location}
                  disabled={isLoading}
                />
                <Autocomplete
                  multiple
                  freeSolo
                  id="instructors"
                  options={instructors}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={values.instructors}
                  onChange={(_, newValue) =>
                    setFieldValue('instructors', newValue)
                  }
                  disabled={isLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Instruktorzy"
                      placeholder="Instruktorzy"
                      error={Boolean(errors.instructors)}
                      helperText={errors.instructors}
                    />
                  )}
                />

                {renderRichTextEditor()}

                <FieldArray
                  name="additionalQuestions"
                  render={(arrayHelpers) => (
                    <div>
                      {values.additionalQuestions &&
                        values.additionalQuestions.map((question, idx) => (
                          <AdditionalQuestionWrapper key={idx}>
                            <Field name="additionalQuestions">
                              {() => (
                                <StyledTextField
                                  name={`additionalQuestions.${idx}`}
                                  type="text"
                                  label={`Dodatkowe pytanie ${idx + 1}`}
                                  fullWidth
                                  sx={{ marginTop: '2rem' }}
                                  value={question}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `additionalQuestions.${idx}`,
                                      event.target.value,
                                    )
                                  }
                                />
                              )}
                            </Field>
                            <DeleteButton
                              type="button"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              <StyledDeleteIcon />
                            </DeleteButton>
                          </AdditionalQuestionWrapper>
                        ))}

                      <TextButton
                        type="button"
                        $mt="2rem"
                        onClick={() => arrayHelpers.push('')}
                      >
                        Dodaj dodatkowe pytanie
                      </TextButton>
                    </div>
                  )}
                />

                {isLoading && <LinearProgress sx={{ marginTop: '2rem' }} />}
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={submitForm}
              >
                {selectedEvent ? 'Zapisz' : 'Dodaj'}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
